p-togglebutton.ng-touched.ng-invalid>.p-togglebutton.p-button {
    border-color: #e24c4c;
}

p-selectbutton.ng-touched.ng-invalid>.p-selectbutton>.p-button {
    border-color: #e24c4c;
}

p-radiobutton.ng-touched.ng-invalid>.p-radiobutton>.p-radiobutton-box {
    border-color: #e24c4c;
}

p-multiselect.ng-touched.ng-invalid>.p-multiselect {
    border-color: #e24c4c;
}

p-listbox.ng-touched.ng-invalid>.p-listbox {
    border-color: #e24c4c;
}

.p-inputtext.ng-touched.ng-invalid {
    border-color: #e24c4c;
}

p-inputswitch.ng-touched.ng-invalid>.p-inputswitch {
    border-color: #e24c4c;
}

p-inputnumber.ng-touched.ng-invalid>.p-inputnumber>.p-inputtext {
    border-color: #e24c4c;
}

p-inputmask.ng-touched.ng-invalid>.p-inputtext {
    border-color: #e24c4c;
}

p-dropdown.ng-touched.ng-invalid>.p-dropdown {
    border-color: #e24c4c;
}

p-chips.ng-touched.ng-invalid>.p-chips>.p-inputtext {
    border-color: #e24c4c;
}

p-checkbox.ng-touched.ng-invalid>.p-checkbox>.p-checkbox-box {
    border-color: #e24c4c;
}

p-cascadeselect.ng-touched.ng-invalid>.p-cascadeselect {
    border-color: #e24c4c;
}

p-calendar.ng-touched.ng-invalid>.p-calendar>.p-inputtext {
    border-color: #e24c4c;
}

p-autocomplete.ng-touched.ng-invalid>.p-autocomplete>.p-inputtext {
    border-color: #e24c4c;
}

p-password.ng-touched.ng-invalid>.p-password>.p-inputtext {
    border-color: #e24c4c;
}