$primaryColor: #93C5FD !default;
$primaryLightColor: rgba(147, 197, 253, .5) !default;
$primaryDarkColor: scale-color($primaryColor, $lightness: -5%) !default;
$primaryDarkerColor: scale-color($primaryColor, $lightness: -10%) !default;
$primaryTextColor: #1c2127 !default;
$primary500:#3B82F6 !default;

$highlightBg:rgba(147, 197, 253, .16) !default;
$highlightTextColor: rgba(255,255,255,.87) !default;

@import '../_variables';
@import '../../theme-base/_components';
@import '../_extensions';