/* You can add global styles to this file, and also import other style files */
$gutter: 1rem; //for primeflex grid system

/* Layout */
@import "assets/layout/styles/layout/layout.scss";
@import "assets/layout/styles/validacao.scss";

/* PrimeNG */
@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";

/* Quill Text Editor for p-editor */
@import "../node_modules/quill/dist/quill.core.css";
@import "../node_modules/quill/dist/quill.snow.css";

/* Demos */
@import "assets/demo/styles/flags/flags.css";
@import "assets/demo/styles/badges.scss";
@import "assets/demo/styles/code.scss";


small.ng-touched.ng-invalid {
    color: #e24c4c;
}

small.ng-dirty.ng-invalid {
    color: #e24c4c;
}

.field-checkbox>label {
    margin-bottom: 7px;
}

.field-set-sm legend {
    padding: 0.5rem !important;
}

.field-sm .field {
    padding: 0.5rem 1rem 0.5rem 1rem;
    margin: 0;
}

.field-set-sm .p-fieldset-content {
    padding: 0.75rem !important;
}

.p-button.p-button-sm {
    padding: 0.3rem 0.5rem !important;
}

.p-button.p-button-sm.p-button-icon-only.p-button-rounded {
    width: 2rem;
    height: 2rem;
    ;
}

[hidden] {
    display: none !important;
}